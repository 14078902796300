import React from 'react'
import cx from 'classnames'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Title from '../../Shared/UI/Title'
import AccessToCommunity from '../../Testosterone101Page/AccessToCommunity'
import PostsGridPreview from '../PostsGridPreview'
import styles from './style.module.sass'

const PostsGrid = ({ data: { blogSettings }, posts, skip, limit, pathPrefix }) => {
  const { listTitle, showEbookForm } = blogSettings
  const postsOnPage = posts.slice(skip, skip + limit)
  const showEbookFormCheckPosition = index => {
    const middle = Math.round(postsOnPage.length / 2)
    if (index + 1 === middle) {
      return true
    }
  }

  return (
    <>
      {listTitle && (
        <Container classes={styles.container}>
          <Wrapper classes={styles.wrapper}>
            <Title classes={styles.title}>{listTitle}</Title>
          </Wrapper>
        </Container>
      )}

      <div className={styles.posts}>
        {postsOnPage.map((post, index) => (
          <div key={post._id}>
            <Container classes={styles.container}>
              <Wrapper classes={styles.wrapper}>
                <PostsGridPreview post={post} postUrl={`${pathPrefix}/${post.slug.current}`} />
              </Wrapper>
            </Container>
            {showEbookForm && showEbookFormCheckPosition(index) && <AccessToCommunity classes={styles.access} />}
          </div>
        ))}
      </div>
    </>
  )
}

export default PostsGrid
