import React, { useRef, useCallback } from 'react'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Title from '../../Shared/UI/Title'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import cx from 'classnames'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'
import './slider.sass'

const Podcasts = ({ podcasts }) => {
  const slider = useRef()
  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: podcasts.length >= 3 ? 3 : podcasts.length,
    initialSlide: 0,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: podcasts.length >= 2 ? 2 : podcasts.length,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const next = useCallback(() => {
    slider.current.slickNext()
  }, [slider])

  const prev = useCallback(() => {
    slider.current.slickPrev()
  }, [slider])

  return (
    <div className={styles.fullBackground}>
      <Container classes={styles.outer}>
        <Wrapper classes={styles.wrapper}>
          <div className={styles.top}>
            <Title>Latest Podcasts</Title>

            <div className={styles.actions}>
              <div onClick={prev} className={styles.leftButton}></div>
              <div onClick={next} className={styles.rightButton}></div>
            </div>
          </div>

          <Slider {...sliderSettings} ref={slider} className={cx('blog-latest-podcasts-slider', styles.slider)}>
            {podcasts.map(podcast => (
              <a key={podcast._key} href={podcast.podcastUrl} target="_blank" className={styles.podcast}>
                <img
                  className={styles.image}
                  {...srcSetProps(sanityImageUrl(podcast.image))}
                  alt={podcast.image?.caption}
                />
                <span className={styles.title}>{podcast.title}</span>
              </a>
            ))}
          </Slider>
        </Wrapper>
      </Container>
    </div>
  )
}

export default Podcasts
