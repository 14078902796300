import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import Button from '../../Shared/UI/Button'
import styles from './style.module.sass'

const PostsGridPreview = ({ post, postUrl, pinned = false }) => {
  const publishDate = date => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }

    return new Date(date).toLocaleDateString('en-US', options)
  }

  return (
    <div
      className={cx(styles.post, {
        [styles.pinned]: pinned,
      })}
    >
      {!pinned && post.imageBlock && (
        <Link className={styles.image} to={postUrl} aria-label={post.title}>
          <img
            {...srcSetProps(
              sanityImageUrl(post.imageBlock),
              [
                [800, 800],
                [480, 480],
              ],
              70,
              1300,
            )}
            alt={post.imageBlock?.caption}
          />
        </Link>
      )}

      <div className={styles.info}>
        {post.tags && post.tags.length > 0 && (
          <div className={styles.tags}>
            {post.tags.map(tag => (
              <div key={tag._id} className={styles.tag}>
                {tag.title}
              </div>
            ))}
          </div>
        )}

        <Link to={postUrl} className={styles.title}>
          <h2>{post.title}</h2>
        </Link>

        <div className={styles.date}>
          {post.publishedAt && <span>{publishDate(post.publishedAt)}</span>}
          {post.readingTime && <span className={styles.readingTime}>{post.readingTime} mins read</span>}
        </div>

        <div className={styles.excerpt}>{prepareParagraph(post.excerpt)}</div>

        {post.author && (
          <div className={styles.author}>
            <img
              className={styles.authorImage}
              {...srcSetProps(sanityImageUrl(post.author?.imageBlock))}
              alt={post.author?.imageBlock?.caption}
            />
            <span className={styles.authorName}>{post.author.name}</span>
          </div>
        )}

        {pinned && (
          <Button classes={styles.readNow} btnType="full" url={postUrl}>
            Read now
          </Button>
        )}
      </div>
    </div>
  )
}

export default PostsGridPreview
