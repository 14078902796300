import React from 'react'
import cx from 'classnames'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Title from '../../Shared/UI/Title'
import { navigate } from 'gatsby'
import styles from './style.module.sass'
import ReactPaginate from 'react-paginate'

const Pagination = ({ numberOfPages, pageNumber, pathPrefix }) => {
  const changeBlogPage = ({ selected }) => {
    const newUrl = selected === 0 ? pathPrefix : `${pathPrefix}/${selected + 1}`
    navigate(newUrl)
  }

  return (
    <>
      {numberOfPages > 1 && (
        <Container>
          <Wrapper classes={styles.wrapper}>
            <ReactPaginate
              className={styles.pagination}
              pageClassName={styles.page}
              pageLinkClassName={styles.pageLink}
              previousClassName={styles.prev}
              previousLinkClassName={styles.prevLink}
              nextClassName={styles.next}
              nextLinkClassName={styles.nextLink}
              activeClassName={styles.active}
              activeLinkClassName={styles.activeLink}
              breakClassName={styles.break}
              breakLinkClassName={styles.breakLink}
              disabledClassName={styles.disabled}
              disabledLinkClassName={styles.disabledLink}
              breakLabel="..."
              nextLabel={null}
              previousLabel={null}
              renderOnZeroPageCount={null}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              pageCount={numberOfPages}
              onPageChange={changeBlogPage}
              forcePage={pageNumber}
            />
          </Wrapper>
        </Container>
      )}
    </>
  )
}

export default Pagination
