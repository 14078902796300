import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import PostsGrid from '../components/Blog/PostsGrid'
import PostsGridPreview from '../components/Blog/PostsGridPreview'
import Pagination from '../components/Blog/Pagination'
import Podcasts from '../components/Blog/Podcasts'

const PinnedPost = ({ post, pathPrefix }) => (
  <PostsGridPreview post={post} postUrl={`${pathPrefix}/${post.slug.current}`} pinned={true} />
)

export default ({ pageContext }) => {
  const { posts, data, pathPrefix } = pageContext
  const { blogSettings, latestPodcasts } = data
  const { showPodcasts, numberOfLatestPodcasts = 6, pinnedPost } = blogSettings

  const getPinnedPost = () => {
    return posts.find(post => post._id.replace('drafts.', '') === pinnedPost._ref)
  }

  return (
    <Layout customHero={pinnedPost ? <PinnedPost post={getPinnedPost()} pathPrefix={pathPrefix} /> : false} data={data}>
      <SEO {...data.seo} />
      <PostsGrid {...pageContext} />
      <Pagination {...pageContext} />
      {showPodcasts && latestPodcasts && (
        <Podcasts podcasts={latestPodcasts.podcasts.slice(0, numberOfLatestPodcasts)} />
      )}
    </Layout>
  )
}
